const formMessages = {
  required: () => 'Campo obrigátorio',
  email: () => 'O email informado não é valido.',
  validateTypeDocument: () => 'Ultilize apenas imagens no formato: PNG ,JPG ou PDF.',
  validateFileSize: () => 'O documento deve ter no maximo 8MB',
  isUniqueCpfCnpj: () => 'O documento informado já está cadastrado.',
  validateFormatCpfCnpj: () => 'Número de Cpf/Cpnj inválido.',
  isUniqueEmail: () => 'O email informado já está cadastrado.',
  minValueSnail: ({ $params }) => `O valor mínimo é de SN$ ${$params.minValueSnail.min},00.`,
  minValueReal: ({ $params }) => `O valor mínimo é de SN$ ${$params.minValueReal.min},00.`,
  balanceSufficient: () => 'Seu saldo é insuficiente',
  requiredWallet: () => 'A carteira é obrigatória.',
  isValidWallet: () => 'Carteira não encontrada.'
};

export default formMessages;
